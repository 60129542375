import { useStaticQuery, graphql } from 'gatsby';

const useAboutPage = () => {
    const { sanityPageAbout: aboutPage } = useStaticQuery(graphql`
        query {
            sanityPageAbout {
                previewImage {
                    ...ImageWithPreview
                }
                previewYear
                previewTitle
                headerTitle
                headerSubTitle
                headerImage {
                    ...ImageWithPreview
                }
                clubStructureTitle
                clubStructure {
                    position
                    staff {
                        staff
                        email
                        image {
                            ...ImageWithPreview
                        }
                        bio
                    }
                }
                clubStructureSecondaryTitle
                clubStructureSecondary {
                    position
                    staff {
                        staff
                        email
                        image {
                            ...ImageWithPreview
                        }
                        bio
                    }
                }
                clubStructureOrgaTitle
                clubStructureOrga
                clubStructureOrgaImage {
                    ...ImageWithPreview
                }
                clubStructureOrgaImageMobile {
                    ...ImageWithPreview
                }
                history {
                    year
                    title
                    _rawContent
                }
                valuesTitle
                _rawValuesContent
                _rawYouthContent
                _rawAcademyContent
                workForUsTitle
                workForUsSubTitle
                workForUs
                workForUsLeftImage {
                    ...ImageWithPreview
                }
                workForUsLeftTitle
                workForUsLeft
                workForUsLeftLink
                workForUsRightImage {
                    ...ImageWithPreview
                }
                workForUsRightTitle
                workForUsRight
                workForUsRightLink
            }
        }
    `);

    return aboutPage;
};

export default useAboutPage;
